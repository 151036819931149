<template>
  <Toast></Toast>
  <Transition name="bounce" mode="out-in">
    <div class="modal-content">
      <!-- Modal Header -->
      <div class="modal-header">
        <h3 class="modal-title">Masukkan Data Pengaduan Nasabah</h3>
        <button @click="toggleView" type="button" class="close" data-dismiss="modal">&times;</button>
      </div>

      <!-- Modal body -->
      <div class="modal-body">
        <div class="alert alert-warning" role="alert">
          <ol style="margin-bottom: 0px;">
            <li>Cari nomor kontrak nasabah terlebih dahulu</li>
            <li>Masukkan tanggal</li>
            <li>Jelaskan permasalahan secara ringkas dan jelas</li>
            <li>Simpan data</li>
          </ol>
        </div>
        <div class="table-responsive">
          <table class="table table-striped ">
            <thead>
            <tr>
              <th scope="col"><span class="header-col-aduan-hmf">Keterangan</span></th>
              <th scope="col"><span class="header-col-aduan-hmf">Data </span></th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>No Pengaduan</td>
              <td>Auto Generated</td>
            </tr>
            <tr>
              <td>* No Kontrak</td>
              <td>
                <input type="text" class="p-inputtext p-component d-none">
                <InputMask v-model="dataForm.nokontrak" mask="99999.99.99.999999" placeholder="00000.00.00.000000"/>
                <InlineMessage severity="error" v-if="v$.dataForm.nokontrak.$error">Mohon isi No Kontrak</InlineMessage>
              </td>
            </tr>
            <tr>
              <td>* Nama Nasabah</td>
              <td>
                <input type="text" class="p-inputtext p-component" v-model="dataForm.namanasabah">
                <InlineMessage severity="error" v-if="v$.dataForm.namanasabah.$error">Mohon isi nama nasabah</InlineMessage>
              </td>
            </tr>
            <tr>
              <td>* Nomor Telepon</td>
              <td>
                <InputNumber type="text" :useGrouping="false" v-model="dataForm.nomortelepon" prefix="+62"/>
                <InlineMessage severity="info" class="ml-2">Isi tanpa awalan 0 di depan</InlineMessage>
                <InlineMessage severity="error" v-if="v$.dataForm.nomortelepon.$error">Mohon isi no telepon</InlineMessage>
              </td>
            </tr>
            <tr>
              <td>* Alamat Email</td>
              <td>
                <input type="text" class="p-inputtext p-component" v-model="dataForm.surel">
                <InlineMessage severity="error" v-if="v$.dataForm.surel.$error">Mohon isi alamat surel</InlineMessage>
              </td>
            </tr>
            <tr>
              <td>* Cabang</td>
              <td>
                <input type="text" class="p-inputtext p-component" v-model="dataForm.cabang">
                <InlineMessage severity="error" v-if="v$.dataForm.cabang.$error">Mohon isi cabang</InlineMessage>
              </td>
            </tr>
            <tr v-if="1===0">
              <td>* Tanggal Aduan</td>
              <td>
                <Calendar id="datepicker-24h" class="p-component" v-model="dataForm.tgladuan" showTime hourFormat="24" fluid />
                <InlineMessage severity="error" v-if="v$.dataForm.tgladuan.$error">Mohon isi tanggal aduan</InlineMessage>
              </td>
            </tr>
            <tr>
              <td>* Layanan/Produk</td>
              <td>
                <ProgressSpinner v-if="loading"></ProgressSpinner>
                <AutoComplete v-else v-model="dataForm.produk" dropdown optionLabel="produk_nama" :suggestions="itemProduct" @complete="searchProduct" ></AutoComplete>
                <InlineMessage severity="error" v-if="v$.dataForm.produk.$error">Mohon isi Layanan/Produk</InlineMessage>
              </td>
            </tr>
            <tr>
              <td>* Kategori</td>
              <td>
                <ProgressSpinner v-if="loading"></ProgressSpinner>
                <AutoComplete v-else v-model="dataForm.kategori" dropdown optionLabel="category" :suggestions="itemsCategory" @complete="searchCategory" ></AutoComplete>
                <InlineMessage severity="error" v-if="v$.dataForm.kategori.$error">Mohon isi Kategori</InlineMessage>
              </td>
            </tr>
            <tr v-if="1===0">
              <td>* Media</td>
              <td>
                <ProgressSpinner v-if="loading"></ProgressSpinner>
                <AutoComplete v-else v-model="dataForm.media" dropdown optionLabel="media_nama" :suggestions="itemsMedia" @complete="searchMedia" ></AutoComplete>
                <InlineMessage severity="error" v-if="v$.dataForm.media.$error">Mohon isi Kategori</InlineMessage>
              </td>
            </tr>
            <tr>
              <td>* Permasalahan</td>
              <td>
                <textarea id="permasalahan" class="p-inputtext p-component" v-model="dataForm.aduan" rows="4" cols="50"></textarea>
                <InlineMessage severity="error" v-if="v$.dataForm.aduan.$error">Mohon isi tanggal aduan</InlineMessage>
              </td>
            </tr>
            <tr>
              <td>Lampiran pendukung</td>
              <td>
                <FileUpload :fileLimit="1" :showUploadButton="false" :showCancelButton="false" :multiple="false" :chooseLabel="uploadLabel" @before-send="beforeUpload" name="fileupload" :url=urlupload :auto="true" accept="image/*, application/*, audio/*, text/plain" :maxFileSize="5000000" @progress="onProgress" @upload="onUpload" @select="onSelect"></FileUpload>
                <InlineMessage severity="info">Maksimal ukuran file 5MB (JPEG/PNG/PDF)</InlineMessage>
                <InlineMessage severity="error" v-if="v$.dataForm.namafile.$error">Silakan upload lampiran pendukung</InlineMessage>
              </td>
            </tr>
            <tr>
              <td>Data Lampiran Terupload</td>
              <td>
                <button v-if="imagescr.length>0" @click="this.visible =!this.visible" type="button" class="btn btn-info btn-sm" data-toggle="modal"
                        data-target="#modalpenanganan">
                  Lihat
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <InlineMessage severity="info" v-if=!buttonactive>{{postmessage}}</InlineMessage>
        <button v-else @click="submitPengaduan" class="btn  btn-md btn-info me-2" type="button" id="btn-req-status"><i
            class="fas fa-caret-right"></i> Kirim Data Aduan
        </button>&nbsp;
        <a href="https://www.hmf.co.id/akun-saya/">
          <button class="btn btn-md btn-outline-secondary" type="button">Kembali</button>
        </a>
      </div>
    </div>
  </Transition>
  <Dialog v-model:visible="visible" :dismissableMask="true" modal header="Data lampiran" :style="{ width: '50%' }">
    <div class="card flex justify-center">
      <Image :src=imagescr alt="Image" width="550" preview />
    </div>
  </Dialog>
</template>


<script>
import FileUpload from 'primevue/fileupload';
import useValidate from "@vuelidate/core";
import DashboardServices from "@/services/dashboard.services";
import {minLength, required, email, numeric} from "@vuelidate/validators";
import Toast from 'primevue/toast';
import InlineMessage from 'primevue/inlinemessage';
import AutoComplete from 'primevue/autocomplete';
import ProgressSpinner from 'primevue/progressspinner';
import InputNumber from 'primevue/inputnumber';
import Dialog from 'primevue/dialog';
import Image from 'primevue/image';
import InputMask from 'primevue/inputmask';
import Calendar from 'primevue/calendar';
export default {
  name: "PageInputAduanWeb",
  components:{InlineMessage,Toast,AutoComplete,ProgressSpinner,InputNumber,FileUpload,Dialog,Image,InputMask,Calendar},
  emits: ['toggle-view'],
  data:()=>({
    v$: useValidate(),
    loading: false,
    response:'',
    message: '',
    postmessage:'',
    success: false,
    itemsCategory: [],
    itemsMedia:[],
    itemProduct:[],
    categoryList:[],
    mediaList:[],
    productList:[],
    uploadLabel:"Pilih file",
    classupload:"",
    urlupload:"",
    imagescr:"",
    visible: false,
    buttonactive: true,
    dataForm:{
      nokontrak:"",
      namanasabah:"",
      nomortelepon:"",
      surel:"",
      cabang:"",
      tgladuan:"",
      kategori:"",
      media:"",
      produk:"",
      aduan:"",
      namafile:"",
      fileid:"",
    }
  }),
  computed:{
    getUserInfo(){
      let userinfo = this.$store.getters.getUserInfo;
      return userinfo.data.fullname;
    }
  },
  methods:{
    setButtonActive(){
      this.buttonactive = true;
      //this.clearData();
    },
    clearData(){
      this.dataForm.nokontrak = "";
      this.dataForm.namanasabah = "";
      this.dataForm.nomortelepon = "";
      this.dataForm.surel = "";
      this.dataForm.cabang = "";
      this.dataForm.tgladuan = "";
      this.dataForm.kategori = "";
      this.dataForm.media = "";
      this.dataForm.produk = "";
      this.dataForm.aduan = "";
      this.dataForm.namafile = "";
      this.dataForm.fileid = "";
    },
    beforeUpload(request){
      let user = JSON.parse(localStorage.getItem('hmfpengaduan'));
      request.xhr.setRequestHeader('Authorization', 'Bearer ' + user.token);
      request.formData.append('paramname','pengaduan');
      return request;
    },
    onProgress(event){
      this.uploadLabel = 'Upload '+event.progress+'%';
    },
    onUpload(event){
      let response = event.xhr.response;
      const obj = JSON.parse(response);
      let message = obj.message;
      if(obj.code===1){
        this.classupload='bg-success';
        this.dataForm.fileid = obj.data.fileid;
        this.$toast.add({ severity: 'success', summary: message, detail: 'File Uploaded', life: 3000 });
      }else{
        this.classupload='bg-danger';
        this.$toast.add({ severity: 'danger', summary: message, detail: 'File Uploaded', life: 3000 });
      }
      this.isupload1 = false;
    },
    onSelect(event){
      if(event.files.length >0){
        //this.uploadLabel1 = event.files[0].name;
      }
    },
    showSuccess(msg,content) {
      this.$toast.add({ severity: 'success', summary: msg, detail: content, life: 3000 });
    },
    showInfo(msg,content) {
      this.$toast.add({ severity: 'info', summary: msg, detail: content, life: 3000 });
    },
    showWarn(msg,content) {
      this.$toast.add({ severity: 'warn', summary: msg, detail: content, life: 3000 });
    },
    showError(msg,content) {
      this.$toast.add({ severity: 'error', summary: msg, detail: content, life: 3000 });
    },
    toggleView(){
      this.$emit('toggle-view',true)
    },
    loadCategory(){
      this.loading = true;
      this.$store.commit('loading',this.loading);
      let data = {limit:10};
      let path = '/dashboard/load-category';
      DashboardServices.postData(data,path).then(
          (response)=>{
            this.response = response.data;
            this.message = response.data.message;
            if(response.data.status===200){
              this.success = true;
              this.categoryList = this.response.data;
            }else {
              this.success = false;
            }
            this.loading = false;
            this.$store.commit('loading',this.loading);
          }
      );
    },
    loadProduct(){
      this.loading = true;
      this.$store.commit('loading',this.loading);
      let data = {limit:10};
      let path = '/dashboard/load-produk';
      DashboardServices.postData(data,path).then(
          (response)=>{
            this.response = response.data;
            this.message = response.data.message;
            if(response.data.status===200){
              this.success = true;
              this.productList = this.response.data;
            }else {
              this.success = false;
            }
            this.loading = false;
            this.$store.commit('loading',this.loading);
          }
      );
    },
    loadMedia(){
      this.loading = true;
      this.$store.commit('loading',this.loading);
      let data = {limit:10};
      let path = '/dashboard/load-media';
      DashboardServices.postData(data,path).then(
          (response)=>{
            this.response = response.data;
            this.message = response.data.message;
            if(response.data.status===200){
              this.success = true;
              this.mediaList = this.response.data;
            }else {
              this.success = false;
            }
            this.loading = false;
            this.$store.commit('loading',this.loading);
          }
      );
    },
    searchMedia(event) {
      setTimeout(() => {
        if (!event.query.trim().length) {
          this.itemsMedia = [...this.mediaList];
        } else {
          this.itemsMedia = this.mediaList.filter((item) => {
            return item.media_nama.toLowerCase().startsWith(event.query.toLowerCase());
          });
        }
      }, 250);
    },
    searchProduct(event) {
      setTimeout(() => {
        if (!event.query.trim().length) {
          this.itemProduct = [...this.productList];
        } else {
          this.itemProduct = this.productList.filter((item) => {
            return item.produk_nama.toLowerCase().startsWith(event.query.toLowerCase());
          });
        }
      }, 250);
    },
    searchCategory(event) {
      setTimeout(() => {
        if (!event.query.trim().length) {
          this.itemsCategory = [...this.categoryList];
        } else {
          this.itemsCategory = this.categoryList.filter((item) => {
            return item.category.toLowerCase().startsWith(event.query.toLowerCase());
          });
        }
      }, 250);
    },
    submitPengaduan(){
      this.buttonactive = false;
      this.imagescr = "";
      this.v$.dataForm.$validate();
      if(!this.v$.dataForm.$error){
        this.loading = true;
        this.$store.commit('loading',this.loading);
        let data = this.dataForm;
        let path = '/dashboard/crud-data-aduan-embed';
        DashboardServices.postData(data,path).then(
            (res)=>{
              this.response = res.data;
              this.message = res.data.message;
              if(res.data.status===200){
                //this.imagescr = this.response.data.image;
                this.success = true;
                setTimeout(this.setButtonActive, 5000)
                this.postmessage = res.data.message;
                this.showSuccess(res.data.message,res.data.message);
              }else {
                this.postmessage = res.data.message;
                this.showError(res.data.message,res.data.message);
                this.success = false;
                this.buttonactive = true;
              }
              this.loading = false;
            }
        );
      }else{
        this.postmessage = "";
        this.buttonactive = true;
        this.loading = false;
        this.message = 'Mohon isi data dengan benar.';
      }
    }
  },
  validations() {
    return {
      dataForm: {
        nokontrak:{required,minLength:minLength(3)},
        namanasabah:{required,minLength:minLength(3)},
        cabang:{required,minLength:minLength(3)},
        surel: {email},
        nomortelepon: {numeric},
        tgladuan:{},
        aduan:{required,minLength:minLength(3)},
        kategori:{required},
        media:{},
        produk:{required},
        namafile:{}
      }
    }
  },
  mounted() {
    let path = '/dashboard/uploadFile';
    this.urlupload = DashboardServices.generateUrl(path);
    this.loadCategory();
    this.loadMedia();
    this.loadProduct();
  }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.bounce-enter-active {
  animation: bounce-in .5s;
}
.bounce-leave-active {
  transform: translateX(20px);
  opacity: 0;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>